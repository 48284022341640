<template>
  <div
    class="tab-pane fade active show"
    id="site-setting"
    role="tabpanel"
    aria-labelledby="site-setting-tab"
  >
    <WeCard class="mb-3">
      <!-- Card Title -->
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0">Site Ayarları</h5>
        </div>
        <div class="col-auto">
          <!-- Submit -->
          <div class="text-center">
            <span class="btn btn-success" v-if="!submit" v-on:click="onSave"
              ><i class="fas fa-save"></i> Kaydet</span
            >
            <span class="btn btn-success" v-else
              ><i class="fas fa-spinner fa-spin"></i
            ></span>
          </div>
          <!-- ./Submit -->
        </div>
      </div>
      <!-- ./Card Title -->
      <hr />

      <!-- Site Title -->
      <WeInput label="Site Başlığı" v-model="data.site_title" />
      <!-- Site Title -->

      <!-- Site URL -->
      <WeInput label="Site URL" v-model="data.site_url" />
      <!-- Site URL -->

      <!-- Meta -->
      <WeMetaData v-model="data.meta" />
      <!-- Meta -->

      <!-- Logo -->
      <WeImageUploader
        v-model="data.logo"
        title="Logo"
        v-bind:mimeBmp="false"
        v-bind:mimeJpeg="false"
        v-bind:mimePng="true"
        v-bind:mimeGif="false"
        v-bind:mimeWebp="false"
        v-bind:mimeTiff="false"
        v-bind:mimeSvg="true"
        v-bind:mimeMp4="false"
        v-bind:mimeMpeg="false"
        v-bind:mimeOgg="false"
        v-bind:mimeWebm="false"
        v-bind:sizeLimitMessage="sizeLimitMessage"
      />
      <!-- Logo -->

      <!-- Current Logo -->
      <div v-if="data.current_logo">
        <label class="custom-label">Mevcut Logo</label>
        <div class="clearfix"></div>
        <WeCard>
          <div class="row align-items-center">
            <div class="col">
              <img
                v-bind:src="data.current_logo"
                width="250"
                class="img-fluid"
              />
            </div>
            <div class="col-auto">
              <span
                class="btn btn-outline-danger btn-sm"
                v-on:click="removeLogo"
                ><i class="fas fa-trash"></i
              ></span>
            </div>
          </div>
        </WeCard>
      </div>
      <!-- Current Logo -->
    </WeCard>

    <WeCard class="mb-3">
      <h5 class="mb-0">İletişim</h5>
      <hr />
      <div class="form-row">
        <!-- Company Title -->
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <WeInput
              label="Şirket Unvanı"
              name="company-title"
              v-model="data.contact.general_title"
            >
              <template #prepend>
                <div class="input-group-text input-max-w">
                  <WeSwitch
                    v-bind:value="getVisibleValue('general_title')"
                    v-on:input="toggleVisibleStatus('general_title', $event)"
                  />
                </div>
              </template>
            </WeInput>
          </div>
        </div>
        <!-- Company Title -->
        <!-- Trademark -->
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <WeInput
              label="Tescilli Marka"
              name="trademark"
              v-model="data.contact.trademark"
            >
              <template #prepend>
                <div class="input-group-text input-max-w">
                  <WeSwitch
                    v-bind:value="getVisibleValue('trademark')"
                    v-on:input="toggleVisibleStatus('trademark', $event)"
                  />
                </div>
              </template>
            </WeInput>
          </div>
        </div>
        <!-- Trademark -->
        <!-- Phone -->
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <WeInput
              label="Telefon Numarası"
              name="phone"
              v-model="data.contact.phone"
            >
              <template #prepend>
                <div class="input-group-text input-max-w">
                  <WeSwitch
                    v-bind:value="getVisibleValue('phone')"
                    v-on:input="toggleVisibleStatus('phone', $event)"
                  />
                </div>
              </template>
            </WeInput>
          </div>
        </div>
        <!-- Phone -->
        <!-- Fax -->
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <WeInput
              label="Faks Numarası"
              name="fax"
              v-model="data.contact.fax"
            >
              <template #prepend>
                <div class="input-group-text input-max-w">
                  <WeSwitch
                    v-bind:value="getVisibleValue('fax')"
                    v-on:input="toggleVisibleStatus('fax', $event)"
                  />
                </div>
              </template>
            </WeInput>
          </div>
        </div>
        <!-- Fax -->
        <!-- Email -->
        <div class="col-12 col-lg-6">
          <WeInput
            label="E-Posta Adresi"
            type="email"
            v-model="data.contact.email"
          >
            <template #prepend>
              <div class="input-group-text input-max-w">
                <WeSwitch
                  v-bind:value="getVisibleValue('email')"
                  v-on:input="toggleVisibleStatus('email', $event)"
                />
              </div>
            </template>
          </WeInput>
        </div>
        <!-- Email -->
        <!-- KEP -->
        <div class="col-12 col-lg-6">
          <WeInput
            label="Kep Adresi"
            placeholder="Kep Adresi"
            name="kep"
            v-model="data.contact.kep"
          >
            <template #prepend>
              <div class="input-group-text input-max-w">
                <WeSwitch
                  v-bind:value="getVisibleValue('kep')"
                  v-on:input="toggleVisibleStatus('kep', $event)"
                />
              </div>
            </template>
          </WeInput>
        </div>
        <!-- KEP -->
        <!-- Tax Office -->
        <div class="col-12 col-lg-6">
          <WeInput
            label="Vergi Dairesi"
            placeholder="Vergi Dairesi"
            name="tax-office"
            v-model="data.contact.tax_office"
          >
            <template #prepend>
              <div class="input-group-text input-max-w">
                <WeSwitch
                  v-bind:value="getVisibleValue('tax_office')"
                  v-on:input="toggleVisibleStatus('tax_office', $event)"
                />
              </div>
            </template>
          </WeInput>
        </div>
        <!-- Tax Office -->
        <!-- Tax Number -->
        <div class="col-12 col-lg-6">
          <WeInput
            name="tax-number"
            label="Vergi Kimlik Numarası"
            placeholder="Vergi Kimlik Numarası"
            v-bind:filter-number="true"
            v-model="data.contact.vkn"
          >
            <template #prepend>
              <div class="input-group-text input-max-w">
                <WeSwitch
                  v-bind:value="getVisibleValue('vkn')"
                  v-on:input="toggleVisibleStatus('vkn', $event)"
                />
              </div>
            </template>
          </WeInput>
        </div>
        <!-- Tax Number -->

        <!-- Job Society -->
        <div class="col-12 col-lg-6">
          <WeInput
            label="Meslek Odası"
            placeholder="Meslek Odası"
            v-model="data.contact.job_society"
          >
            <template #prepend>
              <div class="input-group-text input-max-w">
                <WeSwitch
                  v-bind:value="getVisibleValue('job_society')"
                  v-on:input="toggleVisibleStatus('job_society', $event)"
                />
              </div>
            </template>
          </WeInput>
        </div>
        <!-- Job Society -->

        <!-- Ticaret Sicil No -->
        <div class="col-12 col-lg-6">
          <WeInput
            label="Ticaret Sicil Numarası"
            placeholder="Ticaret Sicil Numarası"
            v-model="data.contact.trade_register_number"
          >
            <template #prepend>
              <div class="input-group-text input-max-w">
                <WeSwitch
                  v-bind:value="getVisibleValue('trade_register_number')"
                  v-on:input="
                    toggleVisibleStatus('trade_register_number', $event)
                  "
                />
              </div>
            </template>
          </WeInput>
        </div>
        <!-- Ticaret Sicil No -->
      </div>

      <!-- Address -->
      <WeInput label="Adres" v-model="data.contact.address">
        <template #prepend>
          <div class="input-group-text input-max-w">
            <WeSwitch
              v-bind:value="getVisibleValue('address')"
              v-on:input="toggleVisibleStatus('address', $event)"
            />
          </div>
        </template>
      </WeInput>
      <!-- Address -->

      <div class="row">
        <!-- Mersis -->
        <div class="col-12 col-lg-6">
          <WeInput
            label="Mersis"
            placeholder="Mersis"
            v-model="data.contact.mersis"
          >
            <template #prepend>
              <div class="input-group-text input-max-w">
                <WeSwitch
                  v-bind:value="getVisibleValue('mersis')"
                  v-on:input="toggleVisibleStatus('mersis', $event)"
                />
              </div>
            </template>
          </WeInput>
        </div>
        <!-- Mersis -->
        <!-- Customer Support Number -->
        <div class="col-12 col-lg-6">
          <WeInput
            label="Müşteri Hizmetleri Numarası"
            placeholder="Müşteri Hizmetleri Numarası"
            v-model="data.contact.customer_support_number"
          >
            <template #prepend>
              <div class="input-group-text input-max-w">
                <WeSwitch
                  v-bind:value="getVisibleValue('customer_support_number')"
                  v-on:input="
                    toggleVisibleStatus('customer_support_number', $event)
                  "
                />
              </div>
            </template>
          </WeInput>
        </div>
        <!-- Customer Support Number -->

        <!-- <div class="col-12">
          <WeInput v-model="data.contact.bank_account" label="Banka Hesabı" />
        </div> -->

        <div class="col-12 col-lg-6">
          <!-- Map Status -->
          <WeSettingCard
            name="map-status"
            title="Harita Göster/Gizle"
            v-model="data.map.show"
          />
          <!-- Map Status -->
          <div class="form-row mt-2" v-if="data.map.show">
            <div class="col-12 col-lg-6">
              <WeInput v-model="data.map.latitude" label="Enlem" />
            </div>
            <div class="col-12 col-lg-6">
              <WeInput v-model="data.map.longtitude" label="Boylam" />
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <!-- WhatsApp -->
          <WeSettingCard
            name="whatsapp-status"
            title="WhatsApp Butonu Göster/Gizle"
            v-model="data.whatsapp.show"
          />
          <!-- WhatsApp -->
          <WePhone
            class="mt-2"
            v-bind:label="$t('whatsappNumber')"
            v-if="data.whatsapp.show"
            v-model="data.whatsapp.number"
          />
        </div>
      </div>
    </WeCard>

    <WeCard class="mb-3">
      <h5 class="mb-0">Sosyal Medya</h5>
      <hr />
      <div class="row">
        <div
          class="col-12 col-lg-6"
          v-for="sm in data.social"
          v-bind:key="sm.name"
        >
          <WeInput
            v-model="sm.value"
            v-bind:prepend="sm.icon"
            v-bind:label="sm.title"
          />
        </div>
      </div>
    </WeCard>

    <WeCard class="mb-3">
      <h5 class="mb-0">Form Verileri</h5>
      <hr />
      <div class="row">
        <div class="col-12 col-lg-6">
          <WeInput
            v-model="data.contactFormMails"
            v-bind:label="'İletişim Formu Mail Listesi (,)'"
          />
        </div>
      </div>
    </WeCard>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "General",
  data() {
    return {
      submit: false,
      editAddress: false,
      data: {
        site_title: this.settings["site.title"],
        site_url: this.settings["site.url"],
        contactFormMails: this.settings["site.contact_form_mails"],
        meta: {
          title: this.settings["site.meta_title"],
          keywords: this.settings["site.meta_keywords"],
          description: this.settings["site.meta_description"],
          robots: this.settings["site.meta_robots"]
            ? this.settings["site.meta_robots"].split(", ")
            : null,
        },
        logo: {
          files: [],
          filesData: null,
        },
        current_logo: this.settings["site.logo"],
        contact: {
          general_title: this.settings["site.general_title"],
          trademark: this.settings["site.trademark"],
          phone: this.settings["site.phone"],
          fax: this.settings["site.fax"],
          email: this.settings["site.email"],
          kep: this.settings["site.kep"],
          tax_office: this.settings["site.tax_office"],
          vkn: this.settings["site.vkn"],
          job_society: this.settings["site.job_society"],
          trade_register_number: this.settings["site.trade_register_number"],
          address: this.settings["site.address"],
          mersis: this.settings["site.mersis"],
          bank_account: this.settings["site.bank_account"],
          customer_support_number:
            this.settings["site.customer_support_number"],
        },
        visible_contacts: this.settings["site.visible_contacts"]
          ? JSON.parse(this.settings["site.visible_contacts"])
          : [],
        map: {
          show: this.settings["site.show_map"] == "1" ? 1 : 0,
          latitude: this.settings["site.map_latitude"],
          longtitude: this.settings["site.map_longtitude"],
        },
        whatsapp: {
          show: this.settings["site.show_whatsapp_button"] == "1" ? 1 : 0,
          number: this.settings["site.whatsapp_number"],
        },
        social: [
          {
            name: "facebook",
            title: "Facebook",
            icon: "<i class='fab fa-facebook-f fa-lg'></i>",
            value: this.settings["site.facebook"],
          },
          {
            name: "twitter",
            title: "Twitter",
            icon: "<i class='fab fa-twitter fa-lg'></i>",
            value: this.settings["site.twitter"],
          },
          {
            name: "instagram",
            title: "Instagram",
            icon: "<i class='fab fa-instagram fa-lg'></i>",
            value: this.settings["site.instagram"],
          },
          {
            name: "youtube",
            title: "YouTube",
            icon: "<i class='fab fa-youtube fa-lg'></i>",
            value: this.settings["site.youtube"],
          },
          {
            name: "linkedin",
            title: "LinkedIn",
            icon: "<i class='fab fa-linkedin-in fa-lg'></i>",
            value: this.settings["site.linkedin"],
          },
        ],
      },
    };
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    ...mapActions("logo", ["upload"]),
    getVisibleValue(key) {
      return this.data.visible_contacts.includes(key);
    },
    toggleVisibleStatus(key, status) {
      let contacts = this.data.visible_contacts;

      if (!status) {
        if (contacts.includes(key)) {
          const index = contacts.indexOf(key);

          contacts.splice(index, 1);
        } else {
          if (status) {
            contacts.push(key);
          }
        }
      } else {
        if (!contacts.includes(key) && status) {
          contacts.push(key);
        }
      }
    },
    onSave() {
      this.submit = true;
      this.settings["site.title"] = this.data.site_title;
      this.settings["site.url"] = this.data.site_url
        ? helper.formatUrl(this.data.site_url)
        : null;
      this.settings["site.meta_title"] = this.data.meta.title;
      this.settings["site.meta_keywords"] = this.data.meta.keywords;
      this.settings["site.meta_description"] = this.data.meta.description;
      this.settings["site.meta_robots"] = this.data.meta.robots
        ? this.data.meta.robots.join(", ")
        : null;

      this.settings["site.general_title"] = this.data.contact.general_title;
      this.settings["site.trademark"] = this.data.contact.trademark;
      this.settings["site.phone"] = this.data.contact.phone;
      this.settings["site.fax"] = this.data.contact.fax;
      this.settings["site.email"] = this.data.contact.email;
      this.settings["site.kep"] = this.data.contact.kep;
      this.settings["site.contact_form_mails"] = this.data.contactFormMails;
      this.settings["site.tax_office"] = this.data.contact.tax_office;
      this.settings["site.vkn"] = this.data.contact.vkn;
      this.settings["site.job_society"] = this.data.contact.job_society;
      this.settings["site.trade_register_number"] =
        this.data.contact.trade_register_number;
      this.settings["site.address"] = this.data.contact.address;
      this.settings["site.mersis"] = this.data.contact.mersis;
      this.settings["site.customer_support_number"] =
        this.data.contact.customer_support_number;

      this.settings["site.address"] = this.data.contact.address;
      this.settings["site.show_map"] = this.data.map.show;
      this.settings["site.map_latitude"] = this.data.map.show
        ? this.data.map.latitude
        : null;
      this.settings["site.map_longtitude"] = this.data.map.show
        ? this.data.map.longtitude
        : null;

      this.settings["site.show_whatsapp_button"] = this.data.whatsapp.show;
      this.settings["site.whatsapp_number"] = this.data.whatsapp.show
        ? this.data.whatsapp.number
        : null;
      this.settings["site.visible_contacts"] = this.data.visible_contacts
        ? JSON.stringify(this.data.visible_contacts)
        : [];
      this.settings["site.bank_account"] = this.data.contact.bank_account;

      // this.settings["site.return_postal_code"] =
      //   this.data.other.return_postal_code || null;

      this.attachSocialMedia();

      const image = this.data.logo.files;
      if (image && image.length) {
        this.upload({
          file: image[0].file,
          onSuccess: (result) => {
            if (result && result.data && result.data.url) {
              this.settings["site.logo"] = result.data.url;
            }
            this.submit = false;
            this.$parent.$emit("on-save", this.settings);
          },
        });
      } else {
        this.submit = false;
        this.$parent.$emit("on-save", this.settings);
      }
    },
    attachSocialMedia() {
      for (let i = 0; i < this.data.social.length; i++) {
        const sm = this.data.social[i];
        const key = "site." + sm.name;
        this.settings[key] = sm.value;
      }
    },
    removeLogo() {
      this.$swal({
        title: "İşlemi Onaylıyor musunuz ?",
        text: "Yeni Logo yüklenmezse; Ayarlar kaydedildiğinde Site Logosu kaldırılacak",
        icon: "warning",
        confirmButtonText: "Sil",
        showCancelButton: true,
        cancelButtonText: "İptal",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.data.current_logo = null;
          this.settings["site.logo"] = null;
        }
      });
    },
    // changePostalCode() {
    //   const neighborhood = this.data.other.return_neighborhood;
    //   if (neighborhood && neighborhood.code) {
    //     this.data.other.return_postal_code = neighborhood.code;
    //   }
    // },
  },
  computed: {
    ...mapState(["shared"]),
    sizeLimitMessage() {
      let result = "En fazla <b>1024 KB</b> boyutunda";
      const logoWidth = this.settings["view.logo_width"];
      const logoHeight = this.settings["view.logo_height"];

      if (logoWidth && logoHeight) {
        result += ` ve <b>${logoWidth}x${logoHeight}</b> çözünürlüğünde`;
      }

      result += " dosya yükleyebilirsiniz.";

      return result;
    },
  },
  watch: {
    "data.whatsapp.show": function (newValue, oldValue) {
      this.$root.$emit("changeWhatsappShow", newValue);
    },
  },
};
</script>
<style lang="scss">
.input-max-w {
  max-width: 52px;
}
</style>